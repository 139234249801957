export function Card({ icon, title, children, color, className = "" }) {
	return (
		<div
			className={`flex items-start rounded-xl bg-white p-4 shadow-lg ${className}`}>
			<div
				className={`flex h-12 w-12 items-center justify-center rounded-full border ${color}`}>
				{icon}
			</div>

			<div className='ml-4 grow'>
				<h2 className='font-semibold'>{title}</h2>
				<div className='mt-2 text-sm text-gray-500'>{children}</div>
			</div>
		</div>
	);
}
