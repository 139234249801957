import { atom } from "recoil";

export const P2pState = atom({
	key: "p2p",
	default: {
		connectedSockets: 0,
		connectedFrontEnds: 0,
		activeConnections: [],
		connectedClients: 0,
		connectedVMs: 0,
		connectedUuids: [],
	},
});
