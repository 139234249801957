export function StyledButton({ children, color = "blue", ...rest }) {
	return (
		<button
			{...rest}
			type='submit'
			className={` text-sm text-white bg-${color}-700 hover:bg-${color}-800 focus:ring-4 focus:outline-none focus:ring-${color}-300 font-medium rounded-lg text-sm px-4 py-2 `}>
			{children}
		</button>
	);
}
