import { Server } from "./Icons";
import React, { useEffect, useRef, useState } from "react";
import { StyledButton } from "./StyledButton";
import { useRecoilState } from "recoil";
import { SettingsState } from "../store/SettingsAtom";

export function ConnectionForm({ connectToServer }) {
	const didMountRef = useRef(false);

	const [connectionSettings, setConnectionSettings] =
		useRecoilState(SettingsState);

	useEffect(() => {
		const cachedSettings =
			localStorage.getItem("server-address") !== null &&
			localStorage.getItem("use-https") !== null;
		if (cachedSettings) {
			setConnectionSettings((settings) => ({
				serverAddress: localStorage.getItem("server-address"),
				useHttps: parseInt(localStorage.getItem("use-https")) === 1,
			}));
		} else {
			setConnectionSettings((settings) => ({
				serverAddress: window.location.hostname,
				useHttps: false,
			}));
			localStorage.setItem("server-address", window.location.hostname);
			localStorage.setItem("use-https", 0);
		}
	}, [setConnectionSettings]);

	useEffect(() => {
		// Check if this is not the first run
		if (!didMountRef.current) {
			didMountRef.current = true;
			return;
		}

		localStorage.setItem("server-address", connectionSettings.serverAddress);
		localStorage.setItem("use-https", connectionSettings.useHttps ? 1 : 0);
	}, [connectionSettings]);
	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					connectToServer();
				}}>
				<label
					htmlFor='default-search'
					className='mb-2 text-sm font-medium text-gray-900 sr-only'>
					Server address
				</label>
				<div className='flex space-x-4 mb-4'>
					<div className='relative'>
						<div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
							<Server className='w-4 h-4' />
						</div>
						<input
							value={connectionSettings.serverAddress}
							onChange={(e) => {
								e.preventDefault();
								setConnectionSettings((settings) => ({
									serverAddress: e.target.value,
									useHttps: settings.useHttps,
								}));
							}}
							type='text'
							className='block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 '
							placeholder='localhost'
							required
						/>
					</div>
					<StyledButton>Connect</StyledButton>
				</div>
				<label className='flex items-center relative w-max cursor-pointer select-none'>
					<span className='text-lg mr-3'>Use https</span>
					<input
						type='checkbox'
						checked={connectionSettings.useHttps}
						onChange={() =>
							setConnectionSettings((settings) => ({
								serverAddress: settings.serverAddress,
								useHttps: !settings.useHttps,
							}))
						}
						className='appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-red-500'
					/>
					<span className='absolute font-medium text-xs uppercase right-1 text-white'>
						{" "}
						OFF{" "}
					</span>
					<span className='absolute font-medium text-xs uppercase right-8 text-white'>
						{" "}
						ON{" "}
					</span>
					<span className='w-7 h-7 right-7 absolute rounded-full transform transition-transform bg-gray-200' />
				</label>
			</form>
		</>
	);
}
