import { useCallback, useState } from "react";
import { Card } from "./Card";
import { Connection, Link, Server, Terminal, Window } from "./Icons";
import wretch from "wretch";
import { useRecoilValue } from "recoil";
import { SettingsState } from "../store/SettingsAtom";
import { P2pState } from "../store/P2pAtom";

export function P2pDetails({ ...rest }) {
	const connectionSettings = useRecoilValue(SettingsState);
	const data = useRecoilValue(P2pState);
	const [activeId, setActiveId] = useState("");
	const [connectionDetails, setConnectionDetails] = useState(null);

	const fetchDetails = useCallback(
		(id) => {
			setActiveId(id);
			wretch(
				`http${connectionSettings.useHttps ? "s" : ""}://${
					connectionSettings.serverAddress
				}/connection-details/${id}`
			)
				.get()
				.json((json) => {
					json.type = {
						0: "UNKNOWN",
						1: "VM",
						2: "CLIENT",
						3: "DASHBOARD",
					}[json.type];
					json.state = {
						0: "IDLE",
						1: "NEGOTIATING",
						2: "CONNECTING",
						3: "CONNECTED",
						4: "CLIENT_CONNECTED",
						5: "CLIENT_DISCONNECTED",
						6: "CLOSING",
						7: "DISCONNECTED",
					}[json.state];
					json.connectedAt = new Date(parseInt(json.connectedAt));
					setConnectionDetails(json);
				});
		},
		[connectionSettings]
	);

	return (
		<div {...rest}>
			<div className='grid grid-cols-2 gap-4'>
				<Card
					icon={<Connection className='h-6 w-6  text-blue-400' />}
					title='Connected sockets'
					color=' border-blue-100 bg-blue-50'>
					{data.connectedSockets}
				</Card>

				<Card
					icon={<Terminal className='h-6 w-6 text-orange-400' />}
					title='Connected front ends'
					color=' border-orange-100 bg-orange-50'>
					{data.connectedFrontEnds}
				</Card>
				<Card
					icon={<Server className='h-6 w-6  text-green-400' />}
					title='Connected VMs'
					color=' border-green-100 bg-green-50'>
					{data.connectedVMs}
				</Card>

				<Card
					icon={<Window className='h-6 w-6 text-indigo-400' />}
					title='Connected clients'
					color=' border-indigo-100 bg-indigo-50'>
					{data.connectedClients}
				</Card>
			</div>
			<Card
				className='my-4'
				icon={<Link className='h-6 w-6  text-red-400' />}
				title='Negotiated connections'
				color=' border-blue-100 bg-red-50'>
				{data.activeConnections.map((item) => (
					<div key={item}>{item}</div>
				))}
			</Card>
			<Card
				className='my-4'
				icon={<Connection className='h-6 w-6 text-green-400' />}
				title='Clients'
				color=' border-green-100 bg-green-50'>
				<div className='flex space-x-4 w-full'>
					<div className='w-1/2 grow divide-y'>
						{data.connectedUuids?.map((item) => (
							<div
								className={`py-2 cursor-pointer ${
									item === activeId
										? "font-semibold rounded-lg bg-gray-100 px-2"
										: ""
								}`}
								key={item}
								onClick={() => fetchDetails(item)}>
								{item}
							</div>
						))}
					</div>
					<div className='w-1/2'>
						{connectionDetails && (
							<table className='break-words w-full'>
								<tbody>
									{Object.entries(connectionDetails).map(([key, value]) => {
										return (
											<tr key={key}>
												<th className='font-semibold text-left align-top border-b border-slate-200 py-2 pr-2'>
													{key}
												</th>
												<td className='break-words border-b border-slate-200 py-2'>
													{key === "addresses"}
													<pre className='break-words'>
														{JSON.stringify(value, null, 2)}
													</pre>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</Card>
		</div>
	);
}
