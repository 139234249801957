import { useEffect, useRef } from "react";

export function Terminal({ entries, ...rest }) {
	const scrollRef = useRef();

	useEffect(() => {
		// console.log("messages updated", messages);
		scrollToBottom();
	}, [entries]);

	const scrollToBottom = () => {
		scrollRef.current.scrollIntoView(); // { behavior: "smooth" }
	};

	return (
		<div {...rest}>
			<div className='w-full shadow-2xl subpixel-antialiased rounded h-64 bg-black border-black mx-auto'>
				<div
					className='flex items-center h-6 rounded-t bg-gray-100 border-b border-gray-500 text-center text-black'
					id='headerTerminal'>
					<div
						className='flex ml-2 items-center text-center border-red-900 bg-red-500  hover:bg-red-400 shadow-inner rounded-full w-3 h-3'
						id='closebtn'></div>
					<div
						className='ml-2 border-yellow-900 bg-yellow-500 hover:bg-yellow-400 shadow-inner rounded-full w-3 h-3'
						id='minbtn'></div>
					<div
						className='ml-2 border-green-900 bg-green-500 hover:bg-green-400 shadow-inner rounded-full w-3 h-3'
						id='maxbtn'></div>
					<div className='mx-auto pr-16' id='terminaltitle'>
						<p className='text-center text-sm'>Terminal</p>
					</div>
				</div>
				<div
					className='pl-1 pt-1 text-green-200 font-mono text-xs bg-black h-72 overflow-y-auto'
					id='console'>
					{entries.map((l, idx) => (
						<p className='pb-1' key={idx}>
							{l}
						</p>
					))}
					<div ref={scrollRef} />
				</div>
			</div>
		</div>
	);
}
