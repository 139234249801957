import "./App.css";
import React, { useRef, useState } from "react";
import { Terminal } from "./components/Terminal";
import { Logo } from "./components/Icons";
import { P2pDetails } from "./components/P2pDetails";
import { ConnectionForm } from "./components/ConnectionForm";
import { StyledButton } from "./components/StyledButton";
import { useRecoilValue, useRecoilState } from "recoil";
import { SettingsState } from "./store/SettingsAtom";
import { P2pState } from "./store/P2pAtom";

const connectionStates = {
	DISCONNECTED: 0,
	CONNECTING: 1,
	CONNECTED: 2,
	ERROR: 3,
};

function App() {
	const [connectionState, setConnectionState] = useState(
		connectionStates.DISCONNECTED
	);
	const [error, setError] = useState("");

	const [log, setLog] = useState([]);

	const [p2pData, setP2pData] = useRecoilState(P2pState);
	const connectionSettings = useRecoilValue(SettingsState);

	const wsRef = useRef(null);

	const connectToServer = () => {
		if (connectionSettings.serverAddress === "") return;

		const ws = new WebSocket(
			`ws${connectionSettings.useHttps ? "s" : ""}://${
				connectionSettings.serverAddress
			}`
		);
		wsRef.current = ws;
		setConnectionState(connectionStates.CONNECTING);
		ws.onopen = (event) => {
			ws.send(JSON.stringify({ type: "connect-frontend" }));
			setConnectionState(connectionStates.CONNECTED);
		};
		ws.onmessage = function (event) {
			const data = JSON.parse(event.data);

			switch (data.type) {
				case "log":
					setLog((prev) => [...prev, data.message]);
					break;
				case "update":
					setP2pData(data.message);
					break;
				default:
					console.log(data);
					break;
			}
		};
		ws.onerror = (error) => {
			setConnectionState(connectionStates.ERROR);

			if (error.target.readyState === 3)
				setError(
					"Something went wrong, connection refused. Is the server running?"
				);
			else setError("Something went wrong");
		};
	};

	const sendCleanupCommand = () => {
		wsRef.current.send(JSON.stringify({ type: "cleanup" }));
	};

	const views = {
		[connectionStates.DISCONNECTED]: (
			<ConnectionForm connectToServer={connectToServer} />
		),
		[connectionStates.CONNECTING]: <>Connecting...</>,
		[connectionStates.ERROR]: (
			<>
				{error}{" "}
				<StyledButton
					onClick={(e) => {
						e.preventDefault();
						setConnectionState(connectionStates.DISCONNECTED);
					}}>
					Back to setup
				</StyledButton>
			</>
		),
		[connectionStates.CONNECTED]: (
			<div className='flex space-x-10'>
				<P2pDetails className='w-full' />
				<Terminal entries={log} className='w-full' />
			</div>
		),
	};

	return (
		<div className='App bg-slate-200 p-12 min-h-screen'>
			<header className='header sticky top-0 bg-white shadow-md flex items-center justify-between px-4 py-4 rounded mb-6'>
				<h1 className='w-3/12 flex space-x-4 items-center font-semibold text-lg'>
					<Logo className='w-8 h-8' />
					<span className=''>P2P console</span>
				</h1>
				{connectionState === connectionStates.CONNECTED && (
					<StyledButton color='red' onClick={sendCleanupCommand}>
						Cleanup server
					</StyledButton>
				)}
			</header>

			<div className='bg-white rounded p-10 shadow-md '>
				{views[connectionState]}
			</div>
		</div>
	);
}

export default App;
